import { Button, ButtonBase, createTheme, useMediaQuery } from "@mui/material"
import { Typography } from "components/typography"
import { getArticleUrlFromTitle, getImagesFromMarkdown } from "components/utils"
import { Link, graphql, navigate, useStaticQuery } from "gatsby"
import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import * as style from "./style.module.scss"
import { ThemeProvider } from "@emotion/react"

const theme = createTheme({
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          padding: "15px 20px",
          border: "2px solid #00afc5",
          backgroundColor: "white",
          color: "#00afc5",
          cursor: "pointer",
          fontFamily: "Arboria-Medium, sans-serif",
          fontSize: "20px",
          textDecoration: "none",
          lineHeight: "28px",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
  },
})

const HomepageArticleView = () => {
  const isWide = useMediaQuery("(min-width:768px)")
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const queryResult = useStaticQuery(graphql`
    {
      allContentfulArticle(limit: 3, filter: { title: { nin: ["DO NOT DELETE", ""] } }, sort: { createdAt: DESC }) {
        nodes {
          title
          lastUpdate
          author {
            name
          }
          faq {
            faq
          }
          createdAt
          seo {
            description
            title
          }
          relatedArticles {
            raw
          }
          longText {
            longText
          }
          readingTime
          summary
          updatedAt
          reference {
            internal {
              content
            }
          }
        }
      }
    }
  `)
  const articles = queryResult.allContentfulArticle.nodes
  return (
    <div className={style.viewContainer}>
      <div className={style.textContainer} id="articles-and-guides">
        <Typography.H2 comp="homepage-section-heading" style={{ textAlign: "center" }}>
          Guides & News
        </Typography.H2>
        <Typography.P comp="homepage-section-subtitle" style={{ textAlign: "center" }}>
          Stay informed with our trending offers, news and how-to guides.
        </Typography.P>
      </div>
      <div className={style.articlesContainer}>
        {articles.map((article: any, idx: number) => {
          const articleImages = getImagesFromMarkdown(article.longText.longText)
          const date = new Date(article.createdAt)
          const month = months[date.getMonth()]
          return (
            <div key={`article-${idx}`} className={style.articleButton}>
              <Link to={"/" + getArticleUrlFromTitle(article.title)} className={style.linkStyle}>
                {articleImages.length > 0 ? <LazyLoadImage src={articleImages[0].url} alt={articleImages[0].alt} className={style.imgStyle} /> : <LazyLoadImage src={"/images/logo.png"} alt={"Stay or Go logo"} className={style.imgStyle} />}
                <div className={style.articleButtonTextContainer}>
                  <div className={style.articleButtonUpperTextContainer}>
                    <Typography.P comp="article-button-type">NEWS</Typography.P>
                    <Typography.P comp="article-button-title">{article.title}</Typography.P>
                  </div>
                  <Typography.P comp="article-button-date" style={{ justifySelf: "end" }}>
                    {date.getDate()} {month} {date.getFullYear()}
                  </Typography.P>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
      <div style={{ display: "flex", justifyContent: "space-around", marginTop: isWide ? "93px" : "50px" }}>
        <ThemeProvider theme={theme}>
          <ButtonBase onClick={() => navigate("/news-and-guides/")}>View all news and guides</ButtonBase>
        </ThemeProvider>
      </div>
    </div>
  )
}

export default HomepageArticleView

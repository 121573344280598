// extracted by mini-css-extract-plugin
export var allNewsButtonContainer = "style-module--all-news-button-container--36f68";
export var articleButton = "style-module--article-button--ddc66";
export var articleButtonTextContainer = "style-module--article-button-text-container--e9b09";
export var articleButtonUpperTextContainer = "style-module--article-button-upper-text-container--235cf";
export var articlesContainer = "style-module--articles-container--37bac";
export var buttonStyle = "style-module--button-style--0ac25";
export var imgStyle = "style-module--img-style--1626d";
export var linkStyle = "style-module--link-style--ca4c1";
export var textContainer = "style-module--text-container--ebb91";
export var viewContainer = "style-module--view-container--cdf4e";